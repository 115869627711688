@font-face {
  font-family: "GraphikBlack";
  src: url("/fonts/GraphikBlack.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "GraphikBlackItalic";
  src: url("/fonts/GraphikBlackItalic.otf") format("opentype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "GraphikBold";
  src: url("/fonts/GraphikBold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "GraphicBoldItalic";
  src: url("/fonts/GraphikBoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "GraphikExtraLight";
  src: url("/fonts/GraphikExtraLight.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "GraphikExtraLightItalic";
  src: url("/fonts/GraphikExtraLightItalic.otf") format("opentype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "GraphikMedium";
  src: url("/fonts/GraphikMedium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GraphikMediumItalic";
  src: url("/fonts/GraphikMediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "GraphikRegular";
  src: url("/fonts/GraphikRegular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GraphikRegularItalic";
  src: url("/fonts/GraphikRegularItalic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "GraphikSemibold";
  src: url("/fonts/GraphikSemibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "GraphikSemiboldItalic";
  src: url("/fonts/GraphikSemiboldItalic.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "GraphikSuper";
  src: url("/fonts/GraphikSuper.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "GraphikSuperItalic";
  src: url("/fonts/GraphikSuperItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "GraphikThin";
  src: url("/fonts/GraphikThin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "GraphikThinItalic";
  src: url("/fonts/GraphikThinItalic.otf") format("opentype");
  font-weight: 100;
  font-style: italic;
}
